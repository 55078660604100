import cx from 'classnames';
import React from 'react';

import * as styles from './Hero.module.css';

import Image from '@components/Molecule/Image/Image';
import Video from '@components/Molecule/Video/Video';

const Hero = ({
	image,
	video,
	children,
	type = 'stretch',
	className = '',
}: {
	image?: {
		src?: any;
		alt?: string;
	};
	video?: {
		mp4: string;
		webm: string;
		poster: string;
	};
	children: React.ReactNode;
	type?: 'stretch' | 'stack' | 'box';
	className?: string;
}): JSX.Element => {
	return (
		<section
			className={cx(styles.hero, {
				[styles[`${type}Type`]]: type,
				[styles.tall]: !image,
				[className]: !!className,
			})}>
			{video && (
				<Video
					mp4Src={video?.mp4}
					webmSrc={video?.webm}
					className={styles.video}
					poster={video?.poster}
				/>
			)}

			{image?.src && (
				<Image
					image={image?.src}
					alt={image?.alt}
					className={cx(styles.image, {
						[styles.hide]: video,
					})}
				/>
			)}

			<div className={styles.content}>
				<div
					className={cx('container', styles.layout, {
						'container--bleed': type === 'box',
					})}>
					<div className={styles.copy}>{children}</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
