import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import BreakerImage from '@components/BreakerImage/BreakerImage';
import Hero from '@components/Hero/Hero';
import ButtonGroup from '@components/Layout/ButtonGroup/ButtonGroup';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Button from '@components/Molecule/Button/Button';
import Image from '@components/Molecule/Image/Image';
import EmbedVideo from '@components/Molecule/Video/EmbedVideo';
import StackBox from '@components/StackBox/StackBox';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const AboutPage = ({ data }: { data: any }) => {
	return (
		<Page>
			<SEO
				title="About us | UHV Design"
				description="We specialise in the design, manufacture and supply of innovative HV and UHV motion and heating products. Over 25 years as the market leader. Learn more."
			/>

			<Hero
				image={{
					src: getImage(data.hero),
					alt: 'Shot of UHV device',
				}}
				type="box">
				<Content>
					<MotionH level={2} as="h1">
						About us
					</MotionH>

					<MotionH level={3} as="h2" spacing="xl">
						Our focus
					</MotionH>

					<MotionCopy spacing="sm">
						We specialise in the design, manufacture and supply of
						innovative market-leading HV and UHV motion and heating
						products.
					</MotionCopy>

					<MotionCopy>
						Our primary focus is to provide high-quality,
						low-maintenance manipulation solutions for vacuum
						applications. Excellence is assured through in-house
						control of the entire process from design, to
						manufacture, assembly, test and after sales support. A
						customer-orientated approach combined with a desire to
						achieve unrivalled product excellence is key to our
						continued success.
					</MotionCopy>
				</Content>
			</Hero>

			<PageSection>
				<Columns gap="md">
					<div>
						<EmbedVideo
							src="https://www.youtube.com/watch?v=2YZl2UTiZi0"
							title="We are UHV Design"
							poster={data.vidscreen.childImageSharp.fluid.src}
						/>
					</div>

					<Content>
						<MotionH level={3} color="blue">
							Established expertise
						</MotionH>
						<MotionCopy spacing="sm">
							UHV Design has developed and manufactured vacuum
							manipulation solutions for over 25 years. In that
							time, it has accumulated a 'production-proven'
							product range, positioning UHV Design at the
							forefront of high- and ultra-high-vacuum
							manipulation. It also ensures technical knowledge
							and expertise is maintained within the company.
						</MotionCopy>

						<MotionCopy>
							As well as a standard portfolio of products, much of
							UHV Design's heritage is based upon working closely
							with the customer to offer specific custom
							solutions. Today, a high percentage of business is
							generated by the company's ability to adapt and
							modify standard products to meet the needs of
							specific applications.
						</MotionCopy>

						<MotionCopy>
							Over the years, UHV Design has developed ideas in
							conjunction with some of the most prestigious and
							forward-thinking scientific and technical
							institutions. Many of these products have proved so
							popular that they have become standard product
							ranges in their own right.
						</MotionCopy>
					</Content>
				</Columns>
			</PageSection>

			<BreakerImage
				image={getImage(data.device)}
				alt="Device"
				position="top">
				<Content>
					<MotionH level={3} color="blue">
						Continued investment
					</MotionH>

					<MotionCopy spacing="sm">
						It is our continual process of development, review and
						improvement that has enabled UHV Design to stay at the
						forefront of vacuum technology for over a decade.
						Working closely with our customers has not only enabled
						us to assemble a genuinely cutting-edge product
						portfolio, but also gain valuable insight into the
						challenges that vacuum manipulation sometimes involves.
					</MotionCopy>

					<MotionCopy>
						By taking an innovative approach, UHV Design eliminates
						problems that have become commonplace in standard
						manipulation product ranges. For example, the kinematic
						movements of our LSM Z shift range, the magnetic
						coupling technology of our linear and rotary probes, and
						the choice of materials, bearings and sealing methods
						across all of our product ranges, have led to a range of
						significant improvements and developments in the vacuum
						industry.
					</MotionCopy>

					<MotionCopy>
						The rapidly changing face of research technology has led
						to the creation of new scientific disciplines that rely
						upon the capability to move components precisely and
						reliably within ultra clean environments. UHV Design is
						proud to have supplied manipulation solutions to
						pioneering laboratories around the world specialising in
						Nanosciences, Synchrotron and Beamline applications, as
						well as many others.
					</MotionCopy>
				</Content>
			</BreakerImage>

			<PageSection background="grey">
				<Columns gap="xs" align="center">
					<StackBox>
						<Content spacing="sm">
							<MotionH level={4} color="blue">
								ISO Certificate
							</MotionH>

							<MotionCopy font="xs">
								Registered Office & VAT Number
							</MotionCopy>

							<MotionCopy font="xs">
								Registered office: 52C Borough High Street,
								London, SE1 1XN
							</MotionCopy>

							<MotionCopy font="xs">
								Registered in England & Wales No: 4133945
							</MotionCopy>

							<MotionCopy font="xs">
								VAT Reg No. GB 801 245377
							</MotionCopy>
						</Content>

						<Image
							image={getImage(data.certificate)}
							alt="Certificate Number 10832 ISO 9001"
							fit="contain"
							center={true}
						/>
					</StackBox>

					<StackBox>
						<Content spacing="sm">
							<MotionH level={4} color="blue">
								Part of Judges Scientific plc
							</MotionH>

							<MotionCopy font="xs" lineHeight="lg">
								Judges Scientific plc is an AIM-listed company
								specialising in the design and production of
								scientific instruments. Corporate expansion is
								being pursued, both through organic growth
								within its subsidiary companies and through the
								acquisition of top-quality businesses with
								established reputations in world-wide markets.
							</MotionCopy>
						</Content>

						<Image
							image={getImage(data.judgesScientificPlc)}
							alt="Judges Scientific plc"
							fit="contain"
						/>

						<ButtonGroup>
							<Button
								to="https://www.judges.uk.com"
								theme="ghost"
								align="end">
								Visit the Judges Scientific website
							</Button>
						</ButtonGroup>
					</StackBox>
				</Columns>
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "about/hero.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		vidscreen: file(relativePath: { eq: "shared/vidscreen.jpg" }) {
			childImageSharp {
				fluid {
					src
				}
			}
		}
		device: file(relativePath: { eq: "about/device.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		certificate: file(relativePath: { eq: "about/certificate.png" }) {
			childImageSharp {
				gatsbyImageData(width: 235)
			}
		}
		judgesScientificPlc: file(
			relativePath: { eq: "about/judges-scientific-plc.png" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default AboutPage;
