// extracted by mini-css-extract-plugin
export var boxType = "Hero-module--box--type--6kkke";
export var content = "Hero-module--content--ltIuC";
export var copy = "Hero-module--copy--4rB7N";
export var hero = "Hero-module--hero--W1mAD";
export var hide = "Hero-module--hide--dmG70";
export var image = "Hero-module--image--zWBeu";
export var layout = "Hero-module--layout--GhXqd";
export var stackType = "Hero-module--stack--type--YBVBS";
export var stretchType = "Hero-module--stretch--type--QLcWk";
export var tall = "Hero-module--tall--16f1D";
export var video = "Hero-module--video--VQqcU";