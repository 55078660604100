import React from 'react';

const Play = ({ className = '' }: { className?: string }): JSX.Element => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 38 45">
		<path fill="#fff" d="M38 22.5 0 45V0Z" />
	</svg>
);

export default Play;
