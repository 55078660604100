import cx from 'classnames';
import React, { useRef } from 'react';
import ReactPlayer from 'react-player';

import * as styles from './Video.module.css';

const Video = ({
	mp4Src,
	webmSrc,
	className,
	poster,
}: {
	mp4Src: string;
	webmSrc: string;
	poster: string | undefined;
	className?: string | object | undefined;
}) => {
	const videoPlayer = useRef(null);

	return (
		<ReactPlayer
			width="100%"
			height="45.625rem"
			url={[mp4Src, webmSrc]}
			controls={false}
			playing={true}
			// light={poster}
			config={{
				file: {
					attributes: {
						poster: poster,
					},
				},
			}}
			muted
			loop
			className={cx(styles.video, className)}
			ref={videoPlayer}
		/>
	);
};

export default Video;
